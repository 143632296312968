'use client';

import { HTMLAttributes, useEffect, useRef, useCallback } from 'react';
import clsx from 'clsx';
import { MdClose, MdOutlineWarningAmber } from 'react-icons/md';
import FNCButton from '~/components/furniturechoice/fnc/fnc-button';

interface ModalDialogProps extends HTMLAttributes<HTMLDialogElement> {
  isOpen: boolean;
  onModalClose: () => void;
  header: string;
  description: string;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  onPrimaryClick?: () => void;
  onSecondaryClick?: () => void;
}

export default function ModalDialog({
  isOpen,
  header,
  description,
  primaryButtonLabel,
  secondaryButtonLabel,
  onPrimaryClick,
  onSecondaryClick,
  onModalClose,
  ...props
}: ModalDialogProps) {
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    const dialogEl = dialogRef.current as unknown as HTMLDialogElement;
    if (isOpen) {
      dialogEl.show();
    } else {
      dialogEl.close();
    }
  }, [isOpen]);

  const escFunction = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onModalClose();
      }
    },
    [onModalClose],
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  return (
    <>
      <dialog
        role="dialog"
        ref={dialogRef}
        className={clsx('fixed inset-0 z-[700] items-center justify-center bg-transparent backdrop:bg-backdrop', {
          hidden: !isOpen,
        })}
        inert={!isOpen ? '' : undefined}
        onClick={(event) => event.target === dialogRef.current && onModalClose()}
        {...props}
      >
        <div className="flex h-[378px] w-[311px] rounded-md bg-white p-3 scrollbar-hide lg:h-[280px] lg:w-[574px]">
          <button
            className="pointer-events-auto absolute right-1 top-1 cursor-pointer rounded rounded-r-none bg-white p-2"
            onClick={onModalClose}
            aria-label="close"
          >
            <MdClose size={24} />
          </button>
          <div className="flex h-full w-full flex-col gap-6 p-3 lg:gap-5">
            <MdOutlineWarningAmber size={48} className="fill-salmon-5" />
            <h2 className="font-sans text-18 font-bold">{header}</h2>
            <div className="h-[200px] lg:h-[100px]">
              <p className="font-normal text-grey-5">{description}</p>
            </div>
            <div className="w-full border-b-2 border-grey-3" />
            <div className="rtl grid w-full grid-cols-2 gap-2">
              <FNCButton
                className="surface-green-2 order-last h-[48px] w-full rounded-lg font-normal"
                onClick={onPrimaryClick ? onPrimaryClick : onModalClose}
              >
                {primaryButtonLabel ?? 'Close'}
              </FNCButton>
              {secondaryButtonLabel && (
                <FNCButton className="surface-grey-4 h-[48px] w-full rounded-lg font-normal" onClick={onSecondaryClick}>
                  {secondaryButtonLabel}
                </FNCButton>
              )}
            </div>
          </div>
        </div>
      </dialog>

      {isOpen && <div className="fixed inset-0 z-[650] bg-backdrop" onClick={onModalClose} />}
    </>
  );
}
