'use client';

import { useState } from 'react';
import { Account } from 'shared/types/account';
import { Cart } from 'shared/types/cart';
import { FinanceProduct } from 'shared/types/finance/FinanceProducts';
import CheckoutAddressSummary from '~/components/furniturechoice/checkout-address-summary';
import CheckoutOrderSummary from '~/components/furniturechoice/checkout-order-summary';
import CheckoutShippingSummary from '~/components/furniturechoice/checkout-shipping-summary';
import ModalDialog from '~/components/furniturechoice/modal-dialog';
import { useCart } from '~/frontastic/hooks';
import { TasticProps } from '~/frontastic/tastics/types';
import { useFormat } from '~/helpers/hooks/useFormat';
import { DataSourceV2 } from '~/types/datasource';
import CheckoutUSPImages from '~/types/furniturechoice/CheckoutUSPImages';
import PaymentMethods from './payment-methods';

interface PaymentContentManagerProps extends CheckoutUSPImages {
  cartWithAccount: DataSourceV2<{ cart: Cart; account?: Account }>;
  financeTerms: DataSourceV2<FinanceProduct[]>;
}

export default function PaymentContentManager({
  data: { cartWithAccount, financeTerms, freeDeliveryImage, freeReturnsImage, financeImage },
  searchParams,
}: TasticProps<PaymentContentManagerProps>) {
  const cartManager = useCart();
  const errorMessage = searchParams && searchParams.error ? searchParams.error : '';
  const [dialogOpen, setDialogOpen] = useState(false);
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const paymentErrorMessage = errorMessage
    ? formatMessage({ id: 'tastics.checkout.payment.payment-content-manager.return_payment_error' })
    : '';

  return (
    <div className="container mb-20 mt-12 grid grid-cols-1 gap-x-12 gap-y-10 lg:mt-10 lg:grid-cols-2">
      <CheckoutOrderSummary
        cart={cartWithAccount.dataSource.cart}
        freeDeliveryImage={freeDeliveryImage}
        freeReturnsImage={freeReturnsImage}
        financeImage={financeImage}
      />

      <div className="flex w-full flex-col gap-y-8 lg:order-first lg:ml-auto lg:max-w-[481px]">
        <CheckoutAddressSummary cart={cartWithAccount.dataSource.cart} />
        <CheckoutShippingSummary cart={cartWithAccount.dataSource.cart} />
        <PaymentMethods
          cart={cartManager.data || cartWithAccount.dataSource.cart}
          financeProducts={financeTerms.dataSource}
          errorMessage={paymentErrorMessage}
        />
      </div>
      <ModalDialog
        isOpen={dialogOpen}
        onModalClose={() => {
          setDialogOpen(false);
        }}
        header="Error"
        description={errorMessage as string}
      />
    </div>
  );
}
