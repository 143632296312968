import { useContext, useState } from 'react';
import { useRouter } from 'next/navigation';
import { SDKResponse } from '@commercetools/frontend-sdk';
import { Cart } from 'shared/types/cart';
import { PaypalResult } from 'shared/types/payment/paypal';
import FNCButton from '~/components/furniturechoice/fnc/fnc-button';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import { useFormat } from '~/helpers/hooks/useFormat';
import { sendGA4TransactionEvent } from '~/utils/send-ga4-event';
import { createPaypalTransaction } from './createPaypalTransaction';

interface PaypalButtonProps {
  cart: Cart;
  callback: (title: string, message: string) => void;
}

export function PaypalContinueToPayBtn({ cart, callback }: PaypalButtonProps) {
  const [paypalToken, setPaypalToken] = useState('');
  const [paypalBusy, setPaypalBusy] = useState(false);
  const router = useRouter();

  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  const { showProgressIndicator, hideProgressIndicator } = useContext(ProgressIndicatorContext);

  const paypalSuccessHandler = (res: SDKResponse<PaypalResult>) => {
    if (res.isError) {
      setPaypalBusy(false);
      hideProgressIndicator();
      return;
    }

    const paypalResult: PaypalResult = res.data;

    if (paypalResult.result == 'Failure') {
      console.log(paypalResult.message);
      callback(
        formatMessage({ id: 'tastics.checkout.payment.payment-content-manager.payment_error' }),
        paypalResult.message,
      );
    } else {
      setPaypalToken(paypalResult.token);
    }

    if (paypalResult.token) {
      // send GA4 event
      sendGA4TransactionEvent('add_payment_info', cart.lineItems ?? []);
      const location = paypalResult.url + '?cmd=_express-checkout&useraction=commit&token=' + paypalResult.token;
      router.push(location);
      return;
    } else {
      setPaypalBusy(false);
      hideProgressIndicator();
    }
  };

  const handlePaypalClick = async () => {
    if (!paypalToken && !paypalBusy) {
      showProgressIndicator();
      setPaypalBusy(true);
      paypalSuccessHandler(await createPaypalTransaction());
    }
  };

  return (
    <FNCButton className="surface-green-2 w-full" onClick={handlePaypalClick} disabled={paypalBusy}>
      {formatMessage({ id: 'tastics.checkout.payment.payment-content-manager.continue_to_pay' })}
    </FNCButton>
  );
}
