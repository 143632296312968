import { FormEvent, useContext, useEffect, useState } from 'react';
import { Cart } from 'shared/types/cart';
import FNCButton from '~/components/furniturechoice/fnc/fnc-button';
import FNCInput from '~/components/furniturechoice/fnc/fnc-input';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import { useFormat } from '~/helpers/hooks/useFormat';
import { sendGA4TransactionEvent } from '~/utils/send-ga4-event';
import DeviceDataCollection from './device-data-collection';
import ExpirationField from './expiration-field';
import MicroformField from './microform-field';
import setInitialPayment from './set-initial-payment';
import useMicroform from './use-microform';

interface MicroformProps {
  cart: Cart;
}

export default function Microform({ cart }: MicroformProps): JSX.Element {
  const [paymentStep, setPaymentStep] = useState<'start' | 'ddc-error' | 'ddc'>('start');
  const [cardExpiration, setCardExpiration] = useState<{ month: string; year: string }>({ month: '', year: '' });
  const [isPayNowDisabled, setIsPayNowDisabled] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const { executeWithProgress } = useContext(ProgressIndicatorContext);
  const { loadFields, unloadFields, createToken } = useMicroform();

  async function createInitialPayment() {
    const captureContext = await executeWithProgress(() => setInitialPayment());

    loadFields(captureContext);
    setPaymentStep('start');
  }

  function restartMicroform() {
    unloadFields();
    createInitialPayment();
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setIsPayNowDisabled(true);
    setHasError(false);

    if (paymentStep === 'start') {
      try {
        await executeWithProgress(() => createToken(cardExpiration));
        // send GA4 event
        sendGA4TransactionEvent('add_payment_info', cart.lineItems ?? []);
        setPaymentStep('ddc');
      } catch {
        setHasError(true);
        restartMicroform();
      }
    } else if (paymentStep === 'ddc-error') {
      setPaymentStep('ddc');
    }
  }

  async function handlePaymentError(type: 'ddc' | 'step-up') {
    setIsPayNowDisabled(false);
    setHasError(true);

    if (type === 'ddc') {
      setPaymentStep('ddc-error');
    } else if (type === 'step-up') {
      restartMicroform();
      setPaymentStep('start');
    }
  }

  useEffect(() => {
    createInitialPayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <form className="flex flex-col gap-y-2" onSubmit={handleSubmit}>
        <MicroformField
          id="microform-card-number-container"
          label={formatMessage({ id: 'tastics.checkout.payment.payment-content-manager.card_number' })}
        />

        <div className="flex flex-col gap-2 lg:flex-row">
          <ExpirationField onChange={(month, year) => setCardExpiration({ month, year })} />

          <MicroformField
            id="microform-security-code-container"
            label={formatMessage({ id: 'tastics.checkout.payment.payment-content-manager.security_code' })}
          />
        </div>

        <FNCInput
          type="text"
          data-testid="name-field"
          className="[&_input]:font-system-ui [&_input]:font-normal"
          label={formatMessage({ id: 'tastics.checkout.payment.payment-content-manager.name_on_card' })}
          isAnimatingLabelDisabled
          required
        />

        <FNCButton
          data-testid="pay-now-button"
          className="surface-green-2 rounded-sm"
          type="submit"
          disabled={isPayNowDisabled}
        >
          {formatMessage({ id: 'tastics.checkout.payment.payment-content-manager.pay_now' })}
        </FNCButton>

        {hasError && (
          <p className="w-full p-4 text-center text-14 text-red-1">
            {formatMessage({ id: 'tastics.checkout.payment.payment-content-manager.payment_verification_error' })}
          </p>
        )}
      </form>

      {paymentStep === 'ddc' && cart.payments?.[0].custom && (
        <DeviceDataCollection
          deviceDataCollectionUrl={cart.payments[0].custom.isv_deviceDataCollectionUrl as string}
          jwt={cart.payments[0].custom.isv_requestJwt as string}
          onPaymentError={handlePaymentError}
        />
      )}
    </>
  );
}
