import { useState, useEffect, useRef, createRef } from 'react';
import { Cart } from 'shared/types/cart';
import { FinanceProduct } from 'shared/types/finance/FinanceProducts';
import ModalDialog from '~/components/furniturechoice/modal-dialog';
import { useFormat } from '~/helpers/hooks/useFormat';
import Cybersource from './cybersource';
import Paypal from './paypal';
import { PaypalContinueToPayBtn } from './paypal/PaypalContinueToPayBtn';
import V12 from './v12';
import { V12ContinueToPayBtn } from './v12/V12ContinueToPayBtn';

interface PaymentMethodsProps {
  cart: Cart;
  financeProducts: FinanceProduct[];
  errorMessage?: string;
}

export default function PaymentMethods({ cart, financeProducts, errorMessage }: PaymentMethodsProps): JSX.Element {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<'card' | 'paypal' | 'v12' | null>(null);
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const [financeTerm, setFinanceTerm] = useState({ interest: 0, term: 0, productId: '', label: '' });
  const [financeDeposit, setFinanceDeposit] = useState('0');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');
  const headerRef = createRef<HTMLHeadingElement>();
  const amount =
    cart.sum && cart.sum.centAmount && cart.sum.fractionDigits
      ? cart.sum.centAmount / 10 ** cart.sum.fractionDigits
      : 0;

  const initialized = useRef(false);

  const messageCallback = (title: string, message: string) => {
    setDialogTitle(title);
    setDialogMessage(message);
    setDialogOpen(true);
  };

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      const selectedPaymentMethod: HTMLInputElement | null = document.querySelector('[name=payment-method]:checked');
      if (selectedPaymentMethod) {
        handlePaymentMethodChange(selectedPaymentMethod.value as 'card' | 'paypal' | 'v12');
      }

      if (errorMessage && headerRef.current instanceof HTMLHeadingElement) {
        headerRef.current?.scrollIntoView();
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handlePaymentMethodChange(type: 'card' | 'paypal' | 'v12') {
    setSelectedPaymentMethod(type);
  }

  return (
    <div className="flex flex-col gap-y-5">
      <h2 ref={headerRef} className="font-sans text-20 font-bold text-black">
        {formatMessage({ id: 'tastics.checkout.payment.payment-content-manager.secure_payment' })}
      </h2>

      {errorMessage && <div className="surface-red-1 p-4 font-normal leading-5">{errorMessage}</div>}

      <fieldset>
        <div>
          <legend className="sr-only">
            {formatMessage({ id: 'tastics.checkout.payment.payment-content-manager.select_payment_method' })}
          </legend>
          <div className="flex flex-col gap-y-4">
            <Cybersource
              cart={cart}
              isSelected={selectedPaymentMethod === 'card'}
              onChange={handlePaymentMethodChange}
            />
            <Paypal onChange={handlePaymentMethodChange} />
            <V12
              onChange={handlePaymentMethodChange}
              setFinanceDeposit={setFinanceDeposit}
              setFinanceTerm={setFinanceTerm}
              cart={cart}
              financeProducts={financeProducts}
              isSelected={selectedPaymentMethod === 'v12'}
            />
          </div>
        </div>
      </fieldset>

      <div>
        {selectedPaymentMethod == 'paypal' && <PaypalContinueToPayBtn cart={cart} callback={messageCallback} />}
        {selectedPaymentMethod == 'v12' && (
          <V12ContinueToPayBtn
            cart={cart}
            amount={amount}
            financeProducts={financeProducts}
            term={financeTerm}
            deposit={financeDeposit}
            callback={messageCallback}
          />
        )}
      </div>

      <ModalDialog
        isOpen={dialogOpen}
        onModalClose={() => {
          setDialogOpen(false);
        }}
        header={dialogTitle}
        description={dialogMessage}
      />
    </div>
  );
}
