import { HTMLAttributes } from 'react';
import { useParams } from 'next/navigation';
import clsx from 'clsx';
import { format } from 'date-fns';
import { Cart } from 'shared/types/cart';
import { CurrencyHelpers } from '~/helpers/currencyHelpers';
import { useFormat } from '~/helpers/hooks/useFormat';
import Link from '../link';

interface CheckoutShippingSummaryProps extends HTMLAttributes<HTMLDivElement> {
  cart: Cart;
}

export default function CheckoutShippingSummary({
  className,
  cart,
  ...props
}: CheckoutShippingSummaryProps): JSX.Element {
  const { locale } = useParams();
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const price = !cart.shippingInfo?.name?.toUpperCase().includes('NOTREADY')
    ? `${CurrencyHelpers.formatForCurrency(
        cart.shippingInfo?.rates?.[0].price || 0,
        locale,
        undefined,
        undefined,
        formatMessage({ id: 'tastics.checkout.shipping.shipping-content-manager.free' }),
      )} — `
    : '';

  return (
    <section
      className={clsx(
        'flex w-full flex-col gap-y-4',
        'after:mt-4 after:block after:h-px after:w-full after:bg-grey-3 after:content-[""]',
        className,
      )}
      {...props}
    >
      <header className="flex items-center gap-x-2 lg:mt-4">
        <h2 className="font-sans text-20 font-semibold text-green-2">
          {formatMessage({ id: 'components.checkout-shipping-summary.delivery' })}
        </h2>

        <Link className="ml-auto text-12 text-grey-4" href="/checkout/shipping">
          {`< ${formatMessage({ id: 'components.checkout-shipping-summary.edit' })}`}
        </Link>
      </header>

      {cart.custom?.deliveryDate && (
        <p className=" surface-grey-5 w-fit rounded-md px-3 py-2 font-bold" data-testid="delivery-date">
          {format(cart.custom.deliveryDate, 'EEEE, dd LLLL yyyy')}
        </p>
      )}

      <div className="flex flex-col gap-y-1">
        <h3 className="font-sans font-bold leading-5">
          {price}
          {cart.shippingInfo?.custom.uiName}
        </h3>
        <p className="text-14 leading-[18px] text-grey-4">{cart.shippingInfo?.custom.uiDescription}</p>
      </div>
    </section>
  );
}
